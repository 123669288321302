<!-- AdComponent.vue -->
<template>
  <div :id="adSlot">
    <ins class="adsbygoogle"
         :style="{ display: 'block' }"
         :data-ad-client="adClient"
         :data-ad-slot="adSlot"
         data-ad-format="auto"
         data-full-width-responsive="true">
    </ins>
  </div>
</template>

<script setup>
import { onMounted, defineProps } from 'vue'

const props = defineProps({
  adClient: {
    type: String,
    required: true
  },
  adSlot: {
    type: String,
    required: true
  }
})

onMounted(() => {
  try {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  } catch (error) {
    console.error('Ad loading error:', error)
  }
})
</script>