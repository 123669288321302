<template>

  <nav v-if="doneTask">
    <router-link to="/">Home</router-link> |
    <router-link to="/mymusic">My Alarms</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/help">Help</router-link>
  </nav>
  <router-view/>
  
</template>

<script>
import StickyAds from './components/StickyAds.vue';
import { trackOn } from './composables/taskTime';
export default{
  setup(){
    const {doneTask} = trackOn()

    return{
      doneTask
    }
  },
  components: {
    StickyAds
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 10px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #6189bb;
}
body{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #aeaeae, #616161);
}
</style>
