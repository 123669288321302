<!-- StickyAds.vue -->
<template>
    <div class="layout-container">
      <!-- Left sticky ad -->
      <div class="sticky-ad left-ad">
        <div class="ad-placeholder">
            <AdComponent
            adClient="ca-pub-8581732170293839"
            adSlot="left-sidebar"
            />
          <!-- span>Left Ad Space</span -->
          <!-- span>160 x 600</span -->
          <p>Are you running an adblocker?</p>
        </div>
      </div>
  
      <!-- Main content -->
      <div class="main-content">
        <slot></slot>
      </div>
  
      <!-- Right sticky ad -->
      <div class="sticky-ad right-ad">
        <div class="ad-placeholder">
          <!-- span>Right Ad Space</span -->
          <!-- span>160 x 600</span -->
          <AdComponent
            adClient="ca-pub-8581732170293839"
            adSlot="right-sidebar"
            />
           <p>I mean who doesn't.</p>
        </div>
      </div>
    </div>
  </template>
  
<script>
import AdComponent from './AdComponent.vue';

export default{
    components: AdComponent
}

</script>

  <style scoped>
  .layout-container {
    display: grid;
    grid-template-columns: 160px 1fr 160px;
    gap: 20px;
    max-width: 1920px;
    margin: 0 auto;
  }
  
  .sticky-ad {
    position: sticky;
    top: 20px;
    height: min-content;
  }
  
  .ad-placeholder {
    width: 160px;
    height: 600px;
    border: 2px dashed #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    font-size: 14px;
    color: #666;
    gap: 8px;
  }
  
  .main-content {
    min-height: 100vh;
    width: 100%;
    max-width: 1920px;
  }
  
  @media (max-width: 1200px) {
    .layout-container {
      grid-template-columns: 120px 1fr 120px;
    }
    .ad-placeholder {
      width: 120px;
    }
  }
  
  @media (max-width: 768px) {
    .layout-container {
      grid-template-columns: 1fr;
    }
    
    .sticky-ad {
      display: none;
    }
  }
  </style>